<script>
import { WButton, WModalFullscreen } from '@WebiumTeam/ui_kit';
import { createNamespacedHelpers } from 'vuex';
import { DOM_ELEMENT_SELECTORS } from '~/constants/dom-elements';
import { DAILY_WORKOUT_TASKS_GOAL } from '~/constants/streaks';
import { SYSTEM_NOTIFICATION_SLUGS } from '~/constants/system-notifications';
import { TOOLTIPS } from '~/constants/tooltips';
import BasePopover from '~/components/_base/popover/_base-popover.vue';
import AttentionTooltip from '~/components/attention-tooltip.vue';
import StreaksAwardPopoverContent from '~/components/streaks/streaks-award-popover-content/streaks-award-popover-content.vue';
import StreaksCounter from '~/components/streaks/streaks-counter.vue';
import StreaksCounterButtonDesktopPopoverContent from '~/components/streaks/streaks-counter-button-desktop-popover-content.vue';
import StreaksCounterButtonMobileDialogContent from '~/components/streaks/streaks-counter-button-mobile-dialog-content.vue';
import StreaksPairedAwardBuddyPopoverContent from '~/components/streaks/streaks-paired/streaks-paired-award/streaks-paired-award-buddy-popover-content.vue';
import StreaksPairedAwardPopoverContent from '~/components/streaks/streaks-paired/streaks-paired-award/streaks-paired-award-popover-content.vue';
import StreaksPairedRemoveButton from '~/components/streaks/streaks-paired/streaks-paired-remove/streaks-paired-remove-button.vue';
import StreaksWorkoutPopoverContent from '~/components/streaks/streaks-workout-progress-popover-content.vue';

const { mapState, mapActions } = createNamespacedHelpers('streaks');
const { mapState: mapMotivationState } = createNamespacedHelpers('motivation');

const destroyPopoverContentTimeout = 200;
const workoutProgressPopoverHideTimeout = 3000;
let workoutProgressPopoverHideTimeoutId;

export const messages = {
  ru: {
    tooltipCloseButtonText: 'понятно',
    tooltipText:
      '<p>здесь ты можешь отслеживать свой стрик<br>на календаре.</p><div style="height: 6px;"></div><p>занимайся ежедневно, чтобы стрик увеличивался. если  пропустишь день занятий — стрик обнулится</p>',
  },
};

export default {
  TOOLTIPS,
  i18n: {
    messages,
  },
  components: {
    AttentionTooltip,
    WButton,
    WModalFullscreen,
    BasePopover,
    StreaksCounter,
    StreaksCounterButtonDesktopPopoverContent,
    StreaksCounterButtonMobileDialogContent,
    StreaksAwardPopoverContent,
    StreaksPairedRemoveButton,
    StreaksPairedAwardBuddyPopoverContent,
    StreaksPairedAwardPopoverContent,
    StreaksWorkoutPopoverContent,
  },
  data() {
    return {
      currentPopover: undefined,
      isPopoverShown: false,
      isMobileDialogVisible: false,
      popoverBoundary: undefined,
      tooltipBoundary: undefined,
    };
  },
  computed: {
    ...mapState([
      'currentStreak',
      'streakReceivedNow',
      'streakReceivedToday',
      'dailyTasksCount',
      'buddyReceivedStreakToday',
      'currentPairedStreak',
      'buddy',
      'pairedStreakReceivedToday',
      'isLoading',
    ]),
    ...mapMotivationState(['achievementsReceivedNow']),
    popovers() {
      return {
        desktop: 'desktop',
        newStreak: 'newStreak',
        newPairStreak: 'newPairStreak',
        newPairBuddyStreak: 'newPairBuddyStreak',
        workoutProgress: 'workoutProgress',
      };
    },
    popperClasses() {
      const classes = [this.$style.popover];
      if (this.currentPopover) {
        classes.push(this.$style[`_${this.currentPopover}`]);
      }
      return classes;
    },
    useAutoHide() {
      return (
        this.currentPopover !== this.popovers.newStreak &&
        this.currentPopover !== this.popovers.newPairStreak
      );
    },
    overflowPadding() {
      return this.$device.isSmallScreen ? 0 : 20;
    },
    isPairedSteakSetUp() {
      return !!this.buddy;
    },
    buddyReceivedStreakSystemNotification() {
      return this.$systemNotifications.getSystemNotification(
        SYSTEM_NOTIFICATION_SLUGS.buddyReceivedStreak
      );
    },
  },
  watch: {
    streakReceivedNow(value) {
      if (value) {
        const hasNotViewedAchievements =
          (this.achievementsReceivedNow || []).length > 0;
        if (hasNotViewedAchievements) {
          const unwatch = this.$watch('achievementsReceivedNow', (newValue) => {
            if (!newValue || newValue.length === 0) {
              this.showNewStreakPopover();
            }
            unwatch();
          });
        } else {
          this.showNewStreakPopover();
        }
      }
    },
    dailyTasksCount(newValue, oldValue) {
      if (workoutProgressPopoverHideTimeoutId) {
        clearTimeout(workoutProgressPopoverHideTimeoutId);
      }
      const handleHidePopover = () => {
        if (this.currentPopover === this.popovers.workoutProgress) {
          this.hidePopover();
        }
      };
      if (this.streakReceivedToday) return;
      if (oldValue === undefined) return;
      if (!newValue) return;
      if (newValue >= DAILY_WORKOUT_TASKS_GOAL) {
        handleHidePopover();
        return;
      }
      this.showWorkoutProgressPopover();
      workoutProgressPopoverHideTimeoutId = setTimeout(() => {
        handleHidePopover();
      }, workoutProgressPopoverHideTimeout);
    },
    isLoading(value) {
      if (value) return;
      if (!this.isPairedSteakSetUp) return;

      this.$systemNotifications.setSystemNotificationSlugsToDebouncedFetch([
        SYSTEM_NOTIFICATION_SLUGS.buddyReceivedStreak,
      ]);
    },
    buddyReceivedStreakSystemNotification(value) {
      if (!value && this.currentPopover === this.popovers.newPairBuddyStreak) {
        this.hidePopover();
        return;
      }
      this.isPopoverShown = true;
      this.currentPopover = this.popovers.newPairBuddyStreak;
    },
  },
  mounted() {
    this.tooltipBoundary = document.querySelector(
      `#${DOM_ELEMENT_SELECTORS.innerLayoutMainId}`
    );
    this.popoverBoundary = document.querySelector(
      `#${DOM_ELEMENT_SELECTORS.innerLayoutWrapperId}`
    );
    if (!this.$device.isSmallScreen) {
      this.$tooltips.show(TOOLTIPS.streaksCalendarDesktop);
    }
  },
  methods: {
    ...mapActions(['markNewStreakAsViewed']),
    onButtonClick() {
      this.$tooltips.hide(TOOLTIPS.streaksCalendarDesktop);
      if (this.$device.isSmallScreen) {
        this.isMobileDialogVisible = true;
        return;
      }
      this.isPopoverShown = !this.isPopoverShown;
      this.currentPopover = this.popovers.desktop;
    },
    showNewStreakPopover() {
      this.isPopoverShown = true;
      this.currentPopover = this.buddyReceivedStreakToday
        ? this.popovers.newPairStreak
        : this.popovers.newStreak;
    },
    showWorkoutProgressPopover() {
      this.isPopoverShown = true;
      this.currentPopover = this.popovers.workoutProgress;
    },
    onHide() {
      this.hidePopover();
      this.$nextTick(() => {
        if (this.currentPopover === this.popovers.newStreak) {
          this.markNewStreakAsViewed();
        }
      });
    },
    onDispose() {
      this.currentPopover = undefined;
    },
    onApplyHide() {
      setTimeout(() => {
        this.currentPopover = undefined;
      }, destroyPopoverContentTimeout);
    },
    hidePopover() {
      this.isPopoverShown = false;
    },
  },
};
</script>

<template>
  <div>
    <BasePopover
      placement="bottom"
      :trigger="[]"
      :auto-hide="useAutoHide"
      :hide-triggers="[]"
      :popper-class="popperClasses"
      :shown="!!isPopoverShown"
      :boundary="popoverBoundary"
      :overflow-padding="overflowPadding"
      show-arrow
      @dispose="onDispose"
      @hide="onHide"
      @apply-hide="onApplyHide"
    >
      <AttentionTooltip
        :tooltip-key="$options.TOOLTIPS.streaksCalendarDesktop"
        :close-button-text="$t('tooltipCloseButtonText')"
        :text="$t('tooltipText')"
        :popper-class="$style.attentionTooltip"
        :boundary="tooltipBoundary"
        disabled-auto-scroll
        placement="bottom"
        max-width="320px"
        strategy="fixed"
      >
        <WButton
          :class="$style.button"
          data-testid="button"
          :size="$device.isSmallScreen ? 'l' : 'm'"
          :only-icon="!isPairedSteakSetUp"
          theme="emptyLight"
          @click="onButtonClick"
        >
          <div :class="$style.streaksWrapper">
            <StreaksCounter
              :count="currentStreak"
              :is-active="streakReceivedToday"
              size="s"
            />
            <StreaksCounter
              v-if="isPairedSteakSetUp"
              :count="currentPairedStreak"
              :is-active="pairedStreakReceivedToday"
              size="s"
              is-paired
            />
          </div>
        </WButton>
      </AttentionTooltip>
      <template #dropdown>
        <StreaksCounterButtonDesktopPopoverContent
          v-if="currentPopover === popovers.desktop"
        />
        <StreaksAwardPopoverContent
          v-if="currentPopover === popovers.newStreak"
        />
        <StreaksPairedAwardPopoverContent
          v-if="currentPopover === popovers.newPairStreak"
        />
        <transition name="fade">
          <StreaksPairedAwardBuddyPopoverContent
            v-if="currentPopover === popovers.newPairBuddyStreak"
          />
        </transition>
        <StreaksWorkoutPopoverContent
          v-if="currentPopover === popovers.workoutProgress"
        />
      </template>
    </BasePopover>
    <WModalFullscreen
      :visible="isMobileDialogVisible"
      hide-header-border
      @close="isMobileDialogVisible = false"
    >
      <template v-if="isPairedSteakSetUp" #headerContent>
        <StreaksPairedRemoveButton :class="$style.pairRemoveButton" />
      </template>
      <StreaksCounterButtonMobileDialogContent />
    </WModalFullscreen>
  </div>
</template>

<style lang="postcss" module>
.button {
  --base-button-padding: 4px 6px 0;

  border: none;
}

.popover {
  z-index: calc(var(--typeform-z-index) + 1);
  margin-top: 10px;
}

.attentionTooltip {
  margin-top: 6px;
}

.pairRemoveButton {
  --base-popper-z-index: 999999;

  width: 100%;
  margin-right: 14px;
  text-align: right;
}

.streaksWrapper {
  display: flex;
  gap: 12px;
}

@media (--small-vp) {
  .button {
    --base-button-padding: 6px 4px 0;
  }

  .streaksWrapper {
    gap: 8px;
  }

  .popover {
    &._newStreak,
    &._newPairStreak {
      --side-gap: 12px;

      width: calc(100vw - var(--side-gap));
      padding-right: var(--side-gap);
    }
  }
}
</style>
