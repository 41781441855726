// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".modal-vk-connect__inner-IL2wC{display:flex;flex-direction:column;align-items:center;justify-content:center;text-align:center}.modal-vk-connect__sticker-oRrhu{width:174px;margin-bottom:28px}.modal-vk-connect__title-LkUGo{margin-bottom:4px;font-size:20px;line-height:24px}.modal-vk-connect__description-7WEFq{font-size:14px;line-height:18px;color:var(--grey-600)}.modal-vk-connect__buttons-1TvAS{display:flex;flex-direction:column;grid-gap:16px;gap:16px;align-items:center;min-width:85%;margin-top:32px}.modal-vk-connect__buttons-1TvAS>*{width:100%}.modal-vk-connect__buttons-1TvAS>*,.modal-vk-connect__buttons-1TvAS>* a{text-transform:none}.modal-vk-connect__issueButton-R9WGc{width:-moz-fit-content;width:fit-content}@media (max-width:1023px){.modal-vk-connect__buttons-1TvAS{min-width:100%}.modal-vk-connect__buttons-1TvAS>*{width:100%}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inner": "modal-vk-connect__inner-IL2wC",
	"sticker": "modal-vk-connect__sticker-oRrhu",
	"title": "modal-vk-connect__title-LkUGo",
	"description": "modal-vk-connect__description-7WEFq",
	"buttons": "modal-vk-connect__buttons-1TvAS",
	"issueButton": "modal-vk-connect__issueButton-R9WGc"
};
module.exports = ___CSS_LOADER_EXPORT___;
