<script>
import { createNamespacedHelpers } from 'vuex';
import { MODALS } from '~/constants/modals';
import {
  COURSE_ONBOARDING_TYPES,
  ONBOARDING_STATES,
  ONBOARDING_URL_PARAM,
} from '~/constants/onboarding';
import { TOOLTIPS } from '~/constants/tooltips';
import {
  closeOnboarding,
  finishOnboarding,
  getIsOnboardingCouldBeShown,
} from '~/api/student-courses';
import { handleError } from '~/services/error-handling/error-handling';
import { getLSItem, setLSItem } from '~/services/local-storage';
import CourseOnboardingModal from '~/components/course-onboarding-modal/course-onboarding-modal.vue';

const { mapState: mapModalsState, mapActions: mapModalsActions } =
  createNamespacedHelpers('modals');
const { mapState: mapCurrentCourseState } =
  createNamespacedHelpers('current-course');

export default {
  MODALS,
  components: {
    CourseOnboardingModal,
  },
  data() {
    return {
      isCompleted: false,
      initialStepKey: this.$route.query[ONBOARDING_URL_PARAM],
    };
  },
  computed: {
    ...mapCurrentCourseState(['currentCourse']),
    ...mapModalsState(['visibleModalName']),
    isGoalRequiredAndNotSelected() {
      return (
        this.currentCourse?.isCourseGoalRequired &&
        !this.currentCourse?.learningGoal
      );
    },
    isCourseContentPage() {
      return this.$route.matched.some(
        (record) => record.name === 'course-content'
      );
    },
  },
  watch: {
    $route() {
      this.$nextTick(() => {
        this.handleOpen();
      });
    },
  },
  mounted() {
    this.handleOpen();
  },
  methods: {
    ...mapModalsActions([
      'closeModal',
      'setVisibleModalNameIfPriorityIsHigher',
    ]),
    close() {
      this.removeStepParamFromUrl();
      this.closeModal();
      this.$tooltips.show(TOOLTIPS.whereIsOnboarding);
    },
    async onClose() {
      try {
        await closeOnboarding(this.currentCourse.id);
        if (this.isCourseContentPage) {
          this.$router.go(-1);
        }
      } catch (error) {
        handleError(error);
      } finally {
        this.close();
      }
    },
    async onFinish() {
      try {
        await finishOnboarding(this.currentCourse.id);
      } catch (error) {
        handleError(error);
      } finally {
        this.close();
      }
    },
    onStepChange(stepKey) {
      this.addStepParamToUrl(stepKey);
    },
    async handleOpen() {
      if (!this.currentCourse) return;
      const noRouteParams = Object.keys(this.$route.params || {}).length === 0;
      if (noRouteParams) return;
      const completedSignatureLSKey = `course_${this.currentCourse.id}_onboarding_completed`;
      if (
        getLSItem(completedSignatureLSKey) &&
        !this.isGoalRequiredAndNotSelected
      )
        return;
      if (
        this.currentCourse.onboardingConfig?.onboardingType !==
        COURSE_ONBOARDING_TYPES.full
      )
        return;
      if (this.$route.query[ONBOARDING_URL_PARAM]) {
        this.openModal();
        return;
      }
      const { couldBeShown, currentState } = await getIsOnboardingCouldBeShown(
        this.currentCourse.id
      );
      this.isCompleted =
        currentState === ONBOARDING_STATES.completed &&
        !this.isGoalRequiredAndNotSelected;

      if (this.isCompleted) {
        setLSItem(completedSignatureLSKey, '1');
      }
      if (this.isGoalRequiredAndNotSelected && this.isCourseContentPage) {
        this.openModal();
        return;
      }

      if (!couldBeShown) return;
      this.openModal();
    },
    openModal() {
      this.setVisibleModalNameIfPriorityIsHigher(MODALS.courseOnboarding);
    },
    addStepParamToUrl(stepKey) {
      if (this.$route.query[ONBOARDING_URL_PARAM] === stepKey) return;
      this.$router.replace({
        query: {
          ...this.$route.query,
          [ONBOARDING_URL_PARAM]: stepKey,
        },
      });
    },
    removeStepParamFromUrl() {
      this.$router.replace({
        query: {
          ...this.$route.query,
          [ONBOARDING_URL_PARAM]: undefined,
        },
      });
    },
  },
};
</script>

<template>
  <transition name="modal">
    <CourseOnboardingModal
      v-if="currentCourse"
      ref="modal"
      :visible="visibleModalName === $options.MODALS.courseOnboarding"
      :is-completed="isCompleted"
      :initial-step-key="initialStepKey"
      @step-change="onStepChange"
      @close="onClose"
      @finish="onFinish"
    />
  </transition>
</template>
